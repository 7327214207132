import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "navbar navbar-expand-lg navbar-dark",
  style: {"background-color":"#26170b"}
}
const _hoisted_2 = { class: "container-fluid" }
const _hoisted_3 = {
  id: "mySidenavMain",
  class: "sidenav"
}
const _hoisted_4 = {
  class: "collapse navbar-collapse",
  id: "navbarLeftAlignExample"
}
const _hoisted_5 = { class: "navbar-nav mr-auto mb-2 mb-lg-0" }
const _hoisted_6 = { class: "nav-item" }
const _hoisted_7 = { class: "nav-item" }
const _hoisted_8 = { class: "nav-item" }
const _hoisted_9 = { class: "nav-item" }
const _hoisted_10 = { class: "nav-item" }
const _hoisted_11 = { class: "nav-item" }
const _hoisted_12 = { class: "nav-item" }
const _hoisted_13 = { class: "nav-item" }
const _hoisted_14 = { class: "nav-item" }
const _hoisted_15 = { class: "nav-item" }
const _hoisted_16 = { class: "nav-item" }
const _hoisted_17 = { class: "nav-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_google = _resolveComponent("google")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Vue3DownUpButton = _resolveComponent("Vue3DownUpButton")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("nav", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", {
          style: {"font-size":"24px","cursor":"pointer","color":"#847e7e","margin-right":"10px"},
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openNav && _ctx.openNav(...args)))
        }, " ☰ "),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("a", {
            href: "javascript:void(0)",
            class: "closebtn",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.closeNav && _ctx.closeNav(...args)))
          }, " × "),
          _createVNode(_component_router_link, {
            to: "/",
            class: "nav-link",
            "aria-current": "page"
          }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createTextVNode(" Головна ")
            ])),
            _: 1
          }),
          _createVNode(_component_router_link, {
            to: "/about",
            class: "nav-link"
          }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createTextVNode("Про церкву")
            ])),
            _: 1
          }),
          _createVNode(_component_router_link, {
            to: "/video",
            class: "nav-link"
          }, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [
              _createTextVNode("Відео")
            ])),
            _: 1
          }),
          _createVNode(_component_router_link, {
            to: "/about_us",
            class: "nav-link"
          }, {
            default: _withCtx(() => _cache[5] || (_cache[5] = [
              _createTextVNode("Про нас")
            ])),
            _: 1
          }),
          _createVNode(_component_router_link, {
            to: "/history",
            class: "nav-link"
          }, {
            default: _withCtx(() => _cache[6] || (_cache[6] = [
              _createTextVNode("Історія")
            ])),
            _: 1
          }),
          _createVNode(_component_router_link, {
            to: "/photo_gallery",
            class: "nav-link"
          }, {
            default: _withCtx(() => _cache[7] || (_cache[7] = [
              _createTextVNode(" Фото галерея ")
            ])),
            _: 1
          }),
          _createVNode(_component_router_link, {
            to: "/google_list",
            class: "nav-link"
          }, {
            default: _withCtx(() => _cache[8] || (_cache[8] = [
              _createTextVNode(" Архів ")
            ])),
            _: 1
          }),
          _createVNode(_component_router_link, {
            to: "/poetry",
            class: "nav-link"
          }, {
            default: _withCtx(() => _cache[9] || (_cache[9] = [
              _createTextVNode(" Поезія ")
            ])),
            _: 1
          }),
          _createVNode(_component_router_link, {
            to: "/books",
            class: "nav-link"
          }, {
            default: _withCtx(() => _cache[10] || (_cache[10] = [
              _createTextVNode(" Книги ")
            ])),
            _: 1
          }),
          _createVNode(_component_router_link, {
            to: "/power_kindness",
            class: "nav-link"
          }, {
            default: _withCtx(() => _cache[11] || (_cache[11] = [
              _createTextVNode(" Волонтерська діяльність церкви ")
            ])),
            _: 1
          }),
          _createVNode(_component_router_link, {
            to: "/contacts",
            class: "nav-link"
          }, {
            default: _withCtx(() => _cache[12] || (_cache[12] = [
              _createTextVNode(" Контакти ")
            ])),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("ul", _hoisted_5, [
            _createElementVNode("li", _hoisted_6, [
              _createVNode(_component_router_link, {
                to: "/",
                class: "nav-link",
                "aria-current": "page"
              }, {
                default: _withCtx(() => _cache[13] || (_cache[13] = [
                  _createTextVNode(" Головна ")
                ])),
                _: 1
              })
            ]),
            _createElementVNode("li", _hoisted_7, [
              _createVNode(_component_router_link, {
                to: "/about",
                class: "nav-link"
              }, {
                default: _withCtx(() => _cache[14] || (_cache[14] = [
                  _createTextVNode("Про церкву")
                ])),
                _: 1
              })
            ]),
            _createElementVNode("li", _hoisted_8, [
              _createVNode(_component_router_link, {
                to: "/video",
                class: "nav-link"
              }, {
                default: _withCtx(() => _cache[15] || (_cache[15] = [
                  _createTextVNode("Відео")
                ])),
                _: 1
              })
            ]),
            _createElementVNode("li", _hoisted_9, [
              _createVNode(_component_router_link, {
                to: "/about_us",
                class: "nav-link"
              }, {
                default: _withCtx(() => _cache[16] || (_cache[16] = [
                  _createTextVNode("Про нас")
                ])),
                _: 1
              })
            ]),
            _createElementVNode("li", _hoisted_10, [
              _createVNode(_component_router_link, {
                to: "/photo_gallery",
                class: "nav-link"
              }, {
                default: _withCtx(() => _cache[17] || (_cache[17] = [
                  _createTextVNode(" Фото галерея ")
                ])),
                _: 1
              })
            ]),
            _createElementVNode("li", _hoisted_11, [
              _createVNode(_component_router_link, {
                to: "/history",
                class: "nav-link"
              }, {
                default: _withCtx(() => _cache[18] || (_cache[18] = [
                  _createTextVNode("Історія")
                ])),
                _: 1
              })
            ]),
            _createElementVNode("li", _hoisted_12, [
              _createVNode(_component_router_link, {
                to: "/google_list",
                class: "nav-link"
              }, {
                default: _withCtx(() => _cache[19] || (_cache[19] = [
                  _createTextVNode("Архів")
                ])),
                _: 1
              })
            ]),
            _createElementVNode("li", _hoisted_13, [
              _createVNode(_component_router_link, {
                to: "/poetry",
                class: "nav-link"
              }, {
                default: _withCtx(() => _cache[20] || (_cache[20] = [
                  _createTextVNode("Поезія")
                ])),
                _: 1
              })
            ]),
            _createElementVNode("li", _hoisted_14, [
              _createVNode(_component_router_link, {
                to: "/cell",
                class: "nav-link"
              }, {
                default: _withCtx(() => _cache[21] || (_cache[21] = [
                  _createTextVNode("Ячейки")
                ])),
                _: 1
              })
            ]),
            _createElementVNode("li", _hoisted_15, [
              _createVNode(_component_router_link, {
                to: "/books",
                class: "nav-link"
              }, {
                default: _withCtx(() => _cache[22] || (_cache[22] = [
                  _createTextVNode("Книги")
                ])),
                _: 1
              })
            ]),
            _createElementVNode("li", _hoisted_16, [
              _createVNode(_component_router_link, {
                to: "/power_kindness",
                class: "nav-link"
              }, {
                default: _withCtx(() => _cache[23] || (_cache[23] = [
                  _createTextVNode("Волонтерська діяльність церкви")
                ])),
                _: 1
              })
            ]),
            _createElementVNode("li", _hoisted_17, [
              _createVNode(_component_router_link, {
                to: "/contacts",
                class: "nav-link"
              }, {
                default: _withCtx(() => _cache[24] || (_cache[24] = [
                  _createTextVNode("Контакти")
                ])),
                _: 1
              })
            ])
          ]),
          _createVNode(_component_google)
        ])
      ])
    ]),
    _createVNode(_component_router_view),
    _createVNode(_component_Vue3DownUpButton, null, {
      default: _withCtx(() => _cache[25] || (_cache[25] = [
        _createElementVNode("svg", {
          width: "2em",
          height: "2em",
          viewBox: "0 0 16 16",
          class: "bi bi-arrow-up-circle-fill",
          fill: "currentColor",
          xmlns: "http://www.w3.org/2000/svg"
        }, [
          _createElementVNode("path", {
            "fill-rule": "evenodd",
            d: "M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z"
          })
        ], -1)
      ])),
      down: _withCtx(() => _cache[26] || (_cache[26] = [
        _createElementVNode("svg", {
          width: "2em",
          height: "2em",
          viewBox: "0 0 16 16",
          class: "bi bi-arrow-down-circle-fill",
          fill: "currentColor",
          xmlns: "http://www.w3.org/2000/svg"
        }, [
          _createElementVNode("path", {
            "fill-rule": "evenodd",
            d: "M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z"
          })
        ], -1)
      ])),
      _: 1
    })
  ], 64))
}